import 'vuetify/styles'
import { createVuetify } from 'vuetify';
import { VDatePicker, VDateCard } from 'vuetify/labs/VDatePicker';
import { VSkeletonLoader } from 'vuetify/labs/VSkeletonLoader';
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases as aliasesMdi, mdi } from 'vuetify/iconsets/mdi'
import { aliases as aliasesMd, md } from 'vuetify/iconsets/md'
import '@mdi/font/css/materialdesignicons.css'; // vuetify by default uses this one / material design icons https://pictogrammers.com/library/mdi/
// import 'material-design-icons-iconfont/dist/material-design-icons.css' // https://fonts.google.com/icons / material icons but not all (use CDN to fetch all)
import 'material-icons/iconfont/material-icons.css'; // https://marella.me/material-icons/demo/
import { en, ru, ro } from 'vuetify/locale';
const vuetify = createVuetify({
    components: {
        ...components,
        VDatePicker,
        VDateCard,
        VSkeletonLoader
    },
    directives,
    locale: {
        locale: 'en',
        fallback: 'en',
        messages: { en, ru, ro }
    },
    icons: {
        defaultSet: 'mdi',
        aliases: {
            ...aliasesMd,
            ...aliasesMdi,
        },
        sets: {
            md,
            mdi,
        }
    },

    defaults: {
        VCard: {
            class: ['rounded-v-card']
        },
        VTextField: {
            color: 'primary',
            class: ['rounded-v-field']
        },
        VTextarea: {
            color: 'primary',
            class: ['rounded-v-field']
        },
        VBtn: {
            class: ['rounded-v-btn']
        },
        VAutocomplete: {
            color: 'primary',
            menuIcon: 'md:expand_more',
            class: ['rounded-v-field']
        },
        VSelect: {
            color: 'primary',
            menuIcon: 'md:expand_more',
            class: ['rounded-v-field']
        },
        VCheckbox: {
            color: 'primary'
        }
    },

    theme: {
        defaultTheme: 'light',
        themes: {
            light: {
                colors: {
                    primary: '#0071FF',
                    background: '#E7F0FA',
                    backgroundLighten: '#F3F5F8',
                    backgroundLightenSecondary: '#F3F5F8',
                    lightBlue: '#0071FF',
                    transparentBlue: '#E7F0FA',
                    darkBlue: '#62758D',
                    darkBlueSecondary: '#62758D',
                    darkenBlue: '#3A414C',
                    darkenBlueBackground: '#3A414C',
                    darkBlueBackground: '#0061DA',
                    veryDarkBlue: '#09121F',
                    veryDarkBlueText: '#09121F',
                    grayBorderColor: '#D4D4E9',
                    grayTableBorderColor: '#B1BBC7',
                    roundedCardBackgroundColor: '#E7EBF0',
                    backgroundGray: '#51657c',
                    indigoDark: '#2A2E35',
                    emptyStatusLine: '#CECDE5',
                    greyHover: '#f5f5f5',
                    contactsBackground: '#B1BBC730',
                    lightGreen: '#B3F0CD',
                    yellowWhite: '#FFF1CE',
                }
            },
            dark: {
                colors: {
                    // background: '#'
                    primary: '#0071FF',
                    lightBlue: '#0071FF',
                    backgroundLighten: '#212121',
                    backgroundLightenSecondary: '#2A2E35',
                    transparentBlue: '#E7F0FA',
                    darkBlue: '#ffffffbf',
                    darkBlueSecondary: '#2A2E35',
                    darkenBlue: '#ffffff80',
                    darkenBlueBackground: '#3A414C',
                    darkBlueBackground: '#0061DA',
                    veryDarkBlue: '#25406C',
                    grayBorderColor: '#D4D4E9',
                    grayTableBorderColor: '#484d52',
                    roundedCardBackgroundColor: '#6b6d71',
                    backgroundGray: '#a6c5e0',
                    indigoDark: '#2A2E35',
                    greyHover: '#313434',
                    contactsBackground: '#2A2E35',
                    lightGreen: '#B3F0CD',
                    yellowWhite: '#FFF1CE',
                }
            }
        }
    }
});
export default vuetify;