import user from "@/interfaces/user";
import {AuthServiceClient, UserServiceClient} from "@pb/user/ServiceServiceClientPb";
import {AuthRequest, IdentityType, SessionDetailsResponse} from "@pb/user/auth_pb";
import {MeRequest, SetMyLanguageRequest} from "@pb/user/user_pb";
import {UserResponse,UserRole} from "@pb/model/merged_pb";
import grpcWeb from "grpc-web";
import userInterface from "@/interfaces/user";
import app from "@/mixins/app";
import {GetUserServiceClient} from "@/grpc/services";

export default {

    mixins: [
        app
    ],

    methods: {
        changeLanguage: function (lang: string) {
            //
            this.setUser({...this.getUser, lang: lang});
            this.getTranslations(lang);
            if (lang !== 'en' && lang) {
                require(`moment/locale/${lang}`);
            }

            this.$vuetify.locale.current = lang;

            this.moment.locale(lang);

            const client = GetUserServiceClient();

            const request = new SetMyLanguageRequest();

            request.setLang(lang);

            client.setMyLanguage(request, null).then(() => {
                //
            });
        },

        setUser: function (payload: user | object) {
            this.$store.commit('setUser', payload);
            localStorage.setItem('app.user', btoa(unescape(encodeURIComponent(JSON.stringify(payload)))));
        },

        logout: function () {
            this.setUser({});
            this.$cookies.remove('token');
            this.$nextTick(() => {
                this.$router.push('/login');
            });
        },

        authorize: function (cb: () => any, errCb: () => any, username: string, password: string) {
            const authServiceClient = new AuthServiceClient(process.env.VUE_APP_GRPC_ENDPOINT);
            const authRequest = new AuthRequest();
            authRequest
                .setIdentity(username)
                .setPassword(password)
                .setIdentityType(IdentityType.USERNAME);

            const meRequest = new MeRequest();

            authServiceClient.authenticate(authRequest, {},
                (err: grpcWeb.RpcError, response: SessionDetailsResponse) => {
                    if (err) {
                        this.error(err.message, {
                            position: "bottom-center"
                        });
                        errCb();
                    } else {
                        const token = response.getToken();

                        const userServiceClient = new UserServiceClient(process.env.VUE_APP_GRPC_ENDPOINT);

                        userServiceClient.me(meRequest, {
                            "Authorization": `Bearer ${token}`,
                        }, (err: grpcWeb.RpcError, response: UserResponse) => {
                            if (err) {
                                this.error(err.message, {
                                    position: "bottom-center"
                                });
                                errCb();
                            } else {
                                let lang = 'en';

                                if (response.getLang()) {
                                    lang = response.getLang();
                                } else {
                                    this.changeLanguage(lang);
                                }

                                const user: userInterface = {
                                    firstName: response.getFirstName(),
                                    lastName: response.getLastName(),
                                    name: `${response.getFirstName()} ${response.getLastName()}`,
                                    role: Object.keys(UserRole)[response.getRole()].toLowerCase(),
                                    lang: lang,
                                    id: response.getId(),
                                    token: token
                                };

                                this.setUser(user);
                                this.$cookies.set('token', token, '1m', '/');
                                this.boot();

                                this.getTranslations(lang);

                                cb();
                            }
                        });
                    }
                });

            return;
        }
    },

    computed: {
        getUserAPICredentials: function () {
            return {
                'Authorization': `Bearer ${this.getToken}`
            }
        },

        isAdmin: function () {
            return this.getUser?.role === 'admin';
        },

        isManager: function () {
            return this.getUser?.role === 'manager';
        },

        isBroker: function () {
            return this.getUser?.role === 'broker';
        },

        getAuthorizationToken: function () {
            return this.getToken;
        },

        getUser: function (): user {
            return this.$store.getters.getUser;
        },

        isAuthorised: function (): boolean {
            return !!(this.getUser.id) && !!(this.$cookies.get('token'));
        },

        getToken: function () {
            return this.getUser?.token;
        }
    }

}