import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import {createStore} from 'vuex';

const store = createStore({
    /* state, actions, mutations */
});
import vuetify from "./plugins/vuetify";
import VueCookies from 'vue3-cookies';
import '@/assets/app.scss';
// @ts-ignore
import Vue3Toastify, {type ToastContainerOptions} from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import mitt from 'mitt';
import '@/plugins/globals';

// @ts-ignore
import globalStore from "@/store/store";
const user = globalStore.getters.getUser;
import 'moment-timezone';
const moment = require('moment');
moment.tz('Europe/Chisinau');

if (user?.lang !== 'en' && user.lang) {
    require(`moment/locale/${user.lang}`);
}

const emitter = mitt();
const app = createApp(App)
    .use(store)
    .use(vuetify)
    .use(router)
    .use(VueCookies, {
        expireTimes: "30d",
        path: "/",
        domain: "",
        secure: true,
        sameSite: "None"
    })
    .use(Vue3Toastify, {
        autoClose: 3000,
    } as ToastContainerOptions)

app.config.globalProperties.emitter = emitter;
app.config.globalProperties.moment = moment;

app.mount('#app');
