import {defineComponent} from "vue";
import app from "@/mixins/app";

export default defineComponent({

    mixins: [
        app
    ],

    methods: {
        addMarker: function (payload: { id: string, location: object }) {
            const index = this.getMarkers.findIndex(_m => _m.id === payload.id);
            if (index >= 0) {
                this.getMarkers[index] = payload;
            } else {
                this.setMarkers([...this.getMarkers, payload]);
            }
        },

        setMapBoundaries: function (items: { location: object }[]) {
            const locations = [];
            items.forEach(item => {
                locations.push(item.location);
            });
            this.emitter.emit('setMapBoundaries', locations);
        },

        setMarkers: function (payload) {
            this.$store.commit('setMarkers', payload);
        },

        addDirection: function (direction: {
            id: string,
            origin: { location: { lat: number, lng: number } },
            destination: { location: { lat: number, lng: number } },
            waypoints?: { location: { lat: number, lng: number }, stopover?: boolean, }[]
        }) {

            const index = this.getDirections.findIndex(d => d.id === direction.id);
            if (index >= 0) {
                this.getDirections[index] = {...direction, response: {}};
            } else {
                this.setDirections([...this.getDirections, {...direction, response: {}}]);
            }
        },

        setDirectionResponse: function (response: object, id: string) {
            this.$store.getters.getDirectionsResponses[id] = response;
        },

        setDirection: function (data: object, id: string) {
            const existentIndex = this.getDirections.findIndex(d => d.id === id);
            if (existentIndex >= 0) {
                this.getDirections[existentIndex] = {
                    ...this.getDirections[existentIndex],
                    ...data
                }
            }
        },

        setDirections: function (payload: []) {
            this.$store.commit('setDirections', payload);
        },


        loadGoogleMapsAPI: function () {
            if (this.getMapLoaded) {
                return;
            }

            const GoogleMapsApiLoader = require('google-maps-api-loader');

            this.$store.commit('setMap', GoogleMapsApiLoader({
                libraries: ['places', 'geometry'],
                language: 'en',
                apiKey: process.env.VUE_APP_GOOGLE_MAP_KEY // optional
            }));
        }
    },

    computed: {
        getMapRadius: function () {
            // Lvov, Ukraine 1000 km
            return {lat: 44.8327182, lng: 24.9342125, radius: 3000000};
        },

        getMapLoaded: function () {
            return this.$store.getters.getMap;
        },

        getMarkers: function () {
            return this.$store.getters.getMarkers;
        },

        getDirections: function () {
            return this.$store.getters.getDirections;
        },
    }

})