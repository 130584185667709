import {defineComponent} from "vue";
import $ from 'jquery';
import {GetLanguageServiceClient, GetUserServiceClient} from "@/grpc/services";
import {ListUsersRequest, ListUsersResponse} from "@pb/user/user_pb";
import {Request as FilterRequest} from "@pb/filter/request_pb";
import {resolve} from "chart.js/helpers";
import translations from "@/mixins/translations";
import {ListLanguagesRequest, ListLanguagesResponse} from "@pb/language/language_pb";

const appUserColorsKey = 'app.user.colors';

export default defineComponent({
    computed: {
        getDateTimeFormat: function () {
            return 'lll';
        },


        getKeepAliveRoutes: function () {
            const items = [];
            this.$router.options.routes.forEach(route => {
                if (route.meta && route.meta.keepAlive) {
                    if (!items.includes(route.name)) {
                        items.push(route.name);
                    }
                }

                if (route.children) {
                    route.children.forEach(childRoute => {
                        if (childRoute.meta && childRoute.meta.keepAlive) {
                            if (!items.includes(childRoute.name)) {
                                items.push(childRoute.name);
                            }
                        }
                    });
                }
            });
            return items;
        },

        getAppTheme: function () {
            return this.$store.getters.getAppTheme;
        },

        getThemeColors: function () {
            return this.$vuetify.theme.themes[this.getAppTheme ? 'dark' : 'light'].colors;
        },

        getAppSavedTheme: function () {
            return this.toBoolean(localStorage.getItem('app.theme'));
        },

        getAppUseSystemTheme: function () {
            return this.toBoolean(localStorage.getItem('app.use.system.theme'));
        },

        getSystemTheme: function () {
            return window.matchMedia("(prefers-color-scheme: dark)").matches;
        },

        getMainRules: function () {
            return {
                required: (value) => {
                    if (value) {
                        return true;
                    }

                    if (value === 0) {
                        return true;
                    }

                    return this.__('LBL_FIELD_REQUIRED');
                },
                email: value => value && value.indexOf("@") >= 0 && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) || this.__('LBL_EMAIL_REQUIRED'),
                counter: (value) => {
                    if (value) {
                        if (value.length >= 50) {
                            return this.__('LBL_LIMIT_OF_CHARACTERS_REACHED');
                        }
                    }
                    return true;
                },
                boolean: value => !!value || this.__('LBL_CHECKBOX_REQUIRED'),
                password: value => value && value.length >= 6 || this.__('LBL_PASSWORD_LENGTH_MUST_BE_6_HIGHER'),
                passportNr: value => value && value.length >= 6 || this.__('LBL_PASSPORT_NUMBER_LENGTH_MUST_BE_6_HIGHER'),
            };
        },

        getUsersColors: function () {
            let localStorageUserColors = localStorage.getItem(appUserColorsKey);

            if (!localStorageUserColors) {
                return {};
            } else {
                localStorageUserColors = JSON.parse(atob(localStorageUserColors));
            }

            return localStorageUserColors;
        },
    },

    methods: {
        ...translations.methods,

        generateUsersColors: function (userId) {
            const colors = [
                '#2196F3',
                '#E91E63',
                '#4CAF50',
                '#673AB7',
                '#FFEB3B',
                '#3F51B5',
                '#F44336',
                '#03A9F4',
                '#00BCD4',
                '#009688',
                '#8BC34A',
                '#FF9800',
                '#9C27B0',
            ];

            let localStorageUserColors: any = localStorage.getItem(appUserColorsKey);
            if (!localStorageUserColors) {
                const users = {};
                users[userId] = colors[0];
                localStorage.setItem(appUserColorsKey, btoa(
                    JSON.stringify(users)
                ));
            } else {
                localStorageUserColors = JSON.parse(atob(localStorageUserColors));

                if (!localStorageUserColors[userId]) {
                    localStorageUserColors[userId] = colors[Object.keys(localStorageUserColors).length];
                }

                localStorage.setItem(appUserColorsKey, btoa(JSON.stringify(localStorageUserColors)));
            }
        },

        toggleTheme: function (payload) {
            this.$vuetify.theme.global.name = payload ? 'dark' : 'light';
            // const theme = useTheme();
            // theme.global.name.value = !payload ? 'light' : 'dark'
        },

        toBoolean: function (string: string | boolean): boolean {
            if (string == "true") {
                return true;
            }
            if (string == "1") {
                return true;
            }
            return false;
        },

        numberWithCommas: function (x) {
            if (!x) {
                return 0;
            }
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },

        dateToSeconds: function (value: string) {
            const returnValue = this.moment(value).format('X');

            if (returnValue === 'Invalid date') {
                return '';
            }
            return returnValue;
        },

        dateTimeToSeconds: function (value: string) {
            const returnValue = this.moment(value, 'YYYY-MM-DD HH:mm').format('X');

            if (returnValue === 'Invalid date') {
                return '';
            }
            return returnValue;
        },

        toDate: function (value: string | number, returnFormat = 'll'): string {
            const returnValue = this.moment(value).format(returnFormat);

            if (returnValue === 'Invalid date') {
                return '';
            }
            return returnValue;
        },

        toDateTime: function (value: string | number, returnFormat = 'YYYY-MM-DD HH:mm'): string {
            const returnValue = this.moment(value).format(returnFormat);

            if (returnValue === 'Invalid date') {
                return '';
            }
            return returnValue;
        },

        formatPhone: function(str) {
            if (!str) {
                return '';
            }

            // return str.replace(/[^0-9\+ ()-]/g, '')
            return str.replace(/[^0-9]/g, '');
                // .replace(new RegExp('\\)', 'g'), '')
                // .replace(new RegExp('\\(', 'g'), '')
                // .replace(new RegExp('-', 'g'), ' ');
        },

        secondsToDate: function (value: number | string, returnFormat = 'll'): string {
            if (typeof value !== 'number') {
                value = parseFloat(value);
            }
            const returnValue = this.moment.unix(value).format(returnFormat);
            if (returnValue === 'Invalid date' || returnValue === '01/01/1970' || returnValue === '1970-01-01') {
                return '';
            }
            return returnValue;
        },

        secondsToTime: function (value: number, returnFormat = 'HH:mm'): string {
            const returnValue = this.moment.unix(value).format(returnFormat);
            if (returnValue === 'Invalid date' || returnValue === '01/01/1970' || returnValue === '1970-01-01') {
                return '';
            }
            return returnValue;
        },

        secondsToDateTime: function (value: number, returnFormat = 'YYYY-MM-DD HH:mm'): string {
            const returnValue = this.moment.unix(value).format(returnFormat);
            if (returnValue === 'Invalid date' || returnValue === '01/01/1970' || returnValue === '1970-01-01') {
                return '';
            }
            return returnValue;
        },

        setAppUseSystemTheme: function (payload: boolean) {
            localStorage.setItem('app.use.system.theme', `${payload}`);
        },

        setAppTheme: function (payload: boolean) {
            this.toggleTheme(payload);
            this.$store.commit('setAppTheme', payload);
            localStorage.setItem('app.theme', `${payload}`);
            $('html').attr('theme', payload ? 'dark' : 'light');
        },

        round: function (num: number) {
            return Math.round(num * 100) / 100;
        },

        boot: function () {
            const client = GetUserServiceClient();

            const request = new ListUsersRequest();
            const filterRequest = new FilterRequest();

            filterRequest.setNrOfItems(200);

            request.setFilter(filterRequest);
            const getUsersPromise = client.list(request, null).then((response: ListUsersResponse) => {
                const users = [];
                response.getItemsList().forEach(user => {
                    users.push(user.toObject());
                });
                this.$store.commit('setAppUsers', users);
            }).catch(() => {
                //
            });

            const requestListLanguages = new ListLanguagesRequest();

            const languageClient = GetLanguageServiceClient();

            const getLanguagesPromise = languageClient.list(requestListLanguages, null).then((response) => {
                const languages = [];
                response.getItemsList().forEach(item => {
                    languages.push(item.toObject());
                });

                this.$store.commit('setLanguages', languages);
            });

            this.$vuetify.locale.current = this.getUser.lang;
            this.moment.locale(this.getUser.lang);

            return [getUsersPromise, getLanguagesPromise];
        }

    },
})