import {defineComponent} from "vue";
import { toast, type ToastOptions } from 'vue3-toastify';
import app from "@/mixins/app";
import grpcWeb from "grpc-web";
export default defineComponent({

    mixins: [
        app
    ],

    methods: {
        notify: function(text: string, options: object = {}) {
            toast(this.__(text), {
                autoClose: 5000,
                position: toast.POSITION.BOTTOM_RIGHT,
                transition: "bounce",
                theme: this.getAppTheme ? 'dark' : 'light',
                ...options,

            } as ToastOptions);
        },

        error: function(text, options: object = {}) {
            options['type'] = 'error';
            this.notify(text, options);
        },

        success: function(text, options: object = {}) {
            options['type'] = 'success';
            this.notify(text, options);
        },

        getErrorMessage: function(error:grpcWeb.RpcError):string {
            return `${error.message} ${error.code}`;
        }

    }

})