import {defineComponent} from "vue";
import axios from "axios";

export default defineComponent({
    methods: {
        getTranslations: function(lang = this.getUser.lang ?? 'en') {
            return axios.get(`${process.env.VUE_APP_REST_API_END_POINT}/api/v1/translations/${lang}/global`)
                .then((response) => {
                this.$store.commit('setTranslations', response.data);
                return response;
            });
        },

        __: function(key:string) {
            const translations = this.$store.getters.getTranslations;

            const key2 = key ? key.replace(new RegExp(' ', 'g'), '') : key;

            return translations[key2] || key;
        },
    }
});